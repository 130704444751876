body {
    font-family: "Trebuchet MS";
    margin: 0;
}

.navbar-custom {
    padding: 0;
    background-color: #aaaaff;
    color: #ffffff;
    border-radius: 0;
    font-weight: bolder;
    font-size: 1.3em;
}

.navbar-custom .navbar-nav>li>a {
    color: #98ff98;
}

.navbar-custom .navbar-nav>.active>a, .navbar-nav>.active>a:hover,
    .navbar-nav>.active>a:focus {
    color: #ff0000;
    background-color: #000000;
}

.navbar-custom .navbar-brand {
    color: #007700;
}

.nav>li>a:hover, .nav>li>a:focus {
    background-color: #8888ff;
}

.alte-header {
    height: 150px;
    background-image: url('./assets/images/Altenieuw.WebsiteTitel.Blauw.png');
    background-size: contain;
    background-repeat: repeat-x;
}

.alte-footer {
    height: 120px;
    background-size: 160px;
    background-repeat: repeat-x;
}

.altenieuw-content {
    padding-top: 1.3em;
    font-size: 14px;
    padding-right: 3em;
    padding-left: 3em;
}

.altenieuw-nieuws {
    padding-right: 5px;
}

.geregistreerden .MuiTableCell-root {
    font-size: 0.8em !important;
}

.geregistreerden tbody tr {
    cursor: pointer;
}

.p-editor-container {
    padding-bottom: 3em;
}

.p-tabmenu .p-tabmenu-nav {
    background-color: #aaaaff;
    border: none;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
    background-color: #aaaaff;
    border: none;
    color: #98ff98;
    font-size: 1.0em;
    font-weight: bold;
    padding: 15px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
    background-color: #9999ee;
    box-shadow: none !important;
    color: #98ff98;
    font-size: 1.0em;
}

.p-menulitem-link {
    padding: 15px;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-highlight,.p-menuitem-link {
    background-color: #9999ee;
    box-shadow: none !important;
    color: #98ff98;
    font-size: 1.0em;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
    background-color: #8888ff;
}

.hoppa {
    background-color: red;
    color: yellow;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:last-child {
    float: right !important;
}
